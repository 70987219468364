import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content, { HTMLContent } from "../components/Content"

const WorkDetailPageTemplate = props => {

  const Wrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 700px;
    font-family: Roboto Condensed;
    flex-direction: column;
  `

  const Title = styled.h1`
    display: inline-flex;
    align-self: start;
    font-family: Teko;
    color: #fff;
    background: #000;
    text-decoration: none;
    text-transform: lowercase;
    padding: 3px 20px 0;
    align-items: center;
    line-height: 1.25;
    font-size: 26px;
    font-weight: normal;
    margin: 0;
  `

  const PageContent = props.contentComponent || Content

  const About = styled(PageContent)`
    h2 {
      display: inline-flex;
      font-family: Teko;
      color: #fff;
      background: #000;
      text-decoration: none;
      padding: 0 20px;
      align-items: center;
      line-height: 1.25;
      font-size: 26px;
      font-weight: normal;
      margin: 0;
    }

    h3 {
      display: inline-flex;
      font-family: Teko;
      color: #000;
      background: none;
      text-decoration: none;
      padding: 0;
      align-items: center;
      line-height: 1.25;
      font-size: 34px;
      font-weight: normal;
      margin: 10px 0 0;
    }

    img {
      border: 2px solid #000;
      object-fit: contain;
      max-width: 700px;
    }

    p {
      margin-top: 3em;
      margin-bottom: 3em;
    }

    .gatsby-resp-image-wrapper {
      margin-bottom: 10px;
      margin-left: 0 !important;
    }

    .embedVideo-container iframe {
      border: 2px solid #000 !important;
      max-width: 100%;
      background: #000;
    }
  `

  const Next = styled(Link)`
    display: inline-flex;
    flex-direction: column;
    font-family: Teko;
    color: #000;
    background: transparent;
    text-decoration: none;
    padding: 0 20px;
    align-items: center;
    line-height: 1.25;
    font-size: 26px;
    text-transform: lowercase;
    font-weight: normal;
    margin-top: 100px;
    margin-bottom: 100px;

    &::after {
      display: inline-block;
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 1.25em;
      line-height: 1;
    }
  `

  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <About content={props.content} />
      {props.nextUrl && (<Next to={`/${props.nextUrl}`}>go to next project</Next>)}
    </Wrapper>
  );
}

const WorkDetailPage = ({ data }) => {
  const { markdownRemark: page, allMarkdownRemark: all } = data
  const nextUrl = get(all.edges.find(edge => edge.node.id === page.id && edge.next), "next.fields.slug")

  return (
    <Layout withHeader backUrl={`/artwork`}>
      <SEO title={page.frontmatter.title} />
      <WorkDetailPageTemplate contentComponent={HTMLContent} title={page.frontmatter.title} content={page.html} nextUrl={nextUrl} />
    </Layout>
  )
}

WorkDetailPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WorkDetailPage

export const workDetailPageQuery = graphql`
  query WorkDetailPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
      }
    }
    allMarkdownRemark(sort: {order: [DESC, DESC], fields: [frontmatter___importance, frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "work-detail-page"}}}) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
        }
      }
    }
  }
`
